import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { current } from "daisyui/src/colors"

export default class extends Controller {
    static targets = [ "hamburger" ]

    initialize() {
        this.hamburgerTarget.addEventListener("click", this.toggleMenu.bind(this))
    }

    toggleMenu() {
        if ( document.getElementById('sidebar').classList.contains("hidden") ) {
            document.getElementById('sidebar').classList.remove('hidden')
        } else {
            document.getElementById('sidebar').classList.add('hidden')
        }
    }
}