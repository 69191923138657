import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "query" ]

  connect() {
    //
  }

  updateWorkshops() {
    // Seatch query
    const searchQuery = this.queryTarget.value;

    // All dives to search through
    const nodes = document.getElementsByClassName('workshop-search-target');

    // Search words in an array
    var filter = [];
    filter.push(searchQuery.toLowerCase().split(" "));
    filter = filter[0];

    // Function to check if the text contains at least one of the search words
    function includesAtLeastOne(t, w) {
      for(let j = 0; j < w.length; j++) {
        if(t.includes(w[j])) return 1
      }
      return 0
    }
    
    for (let i = 0; i < nodes.length; i++) {
      const text = nodes[i].innerText.toLowerCase();
      
      if (includesAtLeastOne(text, filter) || searchQuery === "") {
        nodes[i].style.display = "block";
      } else {
        nodes[i].style.display = "none";
      }
    }
  }
}
