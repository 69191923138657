import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.close()
    }, 8000)
  }

  close() {
    /** @type {HTMLElement} */
    const element = this.element

    element.style.transition = "opacity 0.3s"
    element.style.opacity = 0
    setTimeout(() => element.remove(), 300)
  }
}
