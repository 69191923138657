import { Controller } from '@hotwired/stimulus'
import debounce from 'debounce'

export default class extends Controller {
	// loadingEl = []

	initialize() {
		this._submit = debounce(this._submit.bind(this), 200)

		// this.loadingEl = [
		// 	...this.element.querySelectorAll('[data-loading-text]'),
		// ].map((el) => [el, el.innerText])
		// this.element.addEventListener('turbo:submit-end', () =>
		// 	this.setLoading(false)
		// )
	}

	// setLoading(loading) {
	// 	if (loading)
	// 		this.loadingEl.forEach(
	// 			([el]) => (el.innerHTML = el.dataset.loadingText)
	// 		)
	// 	else
	// 		this.loadingEl.forEach(
	// 			([el, defaultInnerHTML]) => (el.innerHTML = defaultInnerHTML)
	// 		)
	// }

	submit() {
		// this.setLoading(true)
		this._submit()
	}

	_submit() {
		this.element.requestSubmit()
	}
}
