import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'

export default class KPIChartController extends Controller {
  static targets = ['value', 'canvas', 'bgCanvas']

  /** @type {Chart} */
  chart;

  /** @type {Chart} */
  bgChart;

  /** @type {EventListener} */
  eventListener;

  connect() {
    // document.addEventListener('turbo:before-visit', () => {
    //   this.canvasTarget.style.display = 'none'

    //   if (this.valueTarget)
    //     this.valueTarget.innerText = '-'
    // })

    const width = 0.27;
    const colors = ['#054d68'];
    // const colors = ['#f97316', '#eab308', '#22c55e']
    const getColorbyValue = (value) => {
      const index = Math.round(value * (colors.length - 1))
      return {
        index,
        value: colors[index],
      }
    }
    
    const color = getColorbyValue(this.value)
    const elementStyle = this.element.getAttribute('style') || ''
    this.element.setAttribute('style', `--kpi-color: ${color.value}; ${elementStyle}`)

    // Setup background
    this.bgChart = new Chart(this.bgCanvasTarget, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [1],
            borderWidth: 0,
            circumference: 180,
            backgroundColor: ['hsl(195, 20%, 70%)'],
            animation: false,
          },
        ],
      },
      options: {
        hoverOffset: 5,
        cutout: (1 - width) * 100 + '%',
        rotation: 270,
        borderAlign: 'center',
        events: [],
      },
    });

    // Setup primary indicator
    this.chart = new Chart(this.canvasTarget, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [1],
            circumference: this.value * 180,
            borderWidth: 0,
            backgroundColor: [color.value],
          },
          {
            data: [1],
            borderWidth: 0,
            circumference: 180,
            backgroundColor: ['transparent'],
            animation: false,
          },
        ],
      },
      options: {
        hoverOffset: 5,
        cutout: (1 - width * 2) * 100 + '%',
        rotation: 270,
        borderAlign: 'center',
        events: [],
        animation: {
          easing: 'easeOutQuint',
          duration: 500 + 2000 * this.value,
        }
      },
    });

    this.eventListener = window.addEventListener('resize', () => {
      this.chart.resize();
      this.bgChart.resize();
    });
  }

  disconnect () {
    this.eventListener?.remove()
    this.bgChart.destroy()
    this.chart.destroy()
  }

  /**
   * Percentage value
   */
  get value () {
    const rawValue = this.valueTarget?.innerText.replace(',', '.')
    const parsedRawValue = parseFloat(rawValue, 10)

    if (isNaN(parsedRawValue))
      return 0

    // Return normalized percentage value based on scale 0-4 instead of 1-5
    return (parsedRawValue - 1) / 4
  }
}
