import { Controller } from "@hotwired/stimulus"
import { destroy } from "@rails/request.js"


export default class extends Controller {
  static targets = [ "button" ]
  static values = {
    url: String,
  }

  connect() {}

  async remove(e) {
    e.preventDefault()
    destroy(this.urlValue).then((res) => {
      console.log(res)
      this.element.remove()
    }).catch((err) => {
      console.log(err)
    })
  }
}
