import {
    Controller
} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "loader", "iframe" ]

    connect() {
        this.iframeTarget.onload = () => {
            this.loaderTarget.style.display = "none";
            this.iframeTarget.style.display = "block";
		};
    }
}