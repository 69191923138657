import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { with: String }

  oldText = ''

  connect() {
    this.oldText = this.submitButtons()[0].value
    this.element.dataset['action'] = 'submit->disable#disableForm'

    if (!this.hasWithValue) {
      this.withValue = "Sparar..."
    }

    this.element.addEventListener("turbo:submit-end", (e) => {
      this.submitButtons().forEach(button => {
        button.disabled = false
        button.value = this.oldText
      })
    })
  }

  disableForm() {
    this.submitButtons().forEach(button => {
      button.disabled = true
      button.value = this.withValue
    })
  }

  submitButtons() {
    return this.element.querySelectorAll("input[type='submit']")
  }
}