import { put } from '@rails/request.js'
import Sortable from 'sortablejs'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { persist: Boolean, direction: String }
  previousOrder = []

  connect () {
    const direction = this.hasDirectionValue ? this.directionValue : 'vertical'
    const persist = this.hasPersistValue ? this.persistValue : true
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      onStart: () => { this.previousOrder = this.sortable.toArray() },
      onEnd: persist === true ? this.updatePosition.bind(this) : null,
      handle: '.handle',
      ghostClass: 'bg-light',
      direction: direction,
      dataIdAttr: 'data-sgid',
    })
  }

  updatePosition (event) {
    // Sort between siblings
    const elements = Array.from(event.item.parentNode.querySelectorAll(':scope > *[data-sgid]'))
    const position = elements.findIndex(element => element === event.item) + 1

    return put('/positions', {
      body: JSON.stringify({
        sgid: event.item.dataset.sgid,
        position,
      }),
    })
      .then(response => response.ok ? true : Promise.reject(new Error(`HTTP ${response.statusCode} error`)))
      // Revert dragging on fail
      .catch(() => this.sortable.sort(this.previousOrder, true))
  }
}