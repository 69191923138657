import { application } from "./application"
import { registerControllers } from 'stimulus-vite-helpers'
import NestedForm from './nested_form'

import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('alert', Alert)
application.register('autosave', Autosave)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)
application.register('nested-form', NestedForm)

const controllers = import.meta.glob('../**/*_controller.js', { eager: true })
registerControllers(application, controllers)