import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    "intro", 
    "survey", 
    "assertions",
    "progressBar", 
    "progressValue"
  ]

  currentAssertion = -1

  connect () {
    this.onStateChange()
    window.addEventListener('popstate', this.onStateChange.bind(this))
  }
  
  disconnect () {
    window.removeEventListener('popstate', this.onStateChange.bind(this))
  }

  next() {
    if (this.currentAssertion >= 0 && !this.element.checkValidity())
      return this.element.reportValidity()

    history.pushState({ assertion: this.currentAssertion + 1 }, null)
    this.onStateChange()
  }

  previous () {
    history.back()
  }

  /**
   * On page/step change
   */
  onStateChange () {
    const assertion = history.state.assertion ?? -1

    // Survey is completed
    if (assertion > 0 && assertion > this.currentAssertion)
      this.element.requestSubmit()

    // Trigger Rails template update
    if (assertion >= this.assertionsTarget.children.length)
      return setTimeout(() => location.reload(), 0)

    this.currentAssertion = assertion
    this.setProgress()

    /** @type {HTMLDivElement} */
    const assertions = this.assertionsTarget

    // Hide all assertions
    Array.from(assertions.children).forEach(el => {
      el.style.display = 'none'
      el.querySelectorAll('input[type="radio"]').forEach(input => input.required = false)
    })

    if (assertion >= 0) {
      const el = assertions.children[assertion]
      el.style.display = 'block'
      el.querySelectorAll('input[type="radio"]').forEach(input => input.required = true)
    }

    // Show/hide correct wrappers
    if (assertion < 0) {
      this.introTarget.style.display = "block"
      this.surveyTarget.style.display = "none"
      return
    } else {
      this.introTarget.style.display = "none"
      this.surveyTarget.style.display = "block"
    }
  }

  setProgress() {
    this.progressValueTarget.innerText = this.currentAssertion + 1
    this.progressBarTarget.value = this.currentAssertion + 1
  }
}
